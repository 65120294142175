import React, { useState } from 'react';
import InputMask, { InputState } from 'react-input-mask';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { WithStyles } from '@material-ui/styles';
import validationSchema from './validationSchema';
import { isCheckable } from '../../utils/helpers';

import styles from './styles';
import { UserData } from '../../redux/selectors/auth';

type FormData = {
  pass_word: string;
  confirm: string;
} & UserData;

type Props = {
  user: UserData;
  onSave: (payload: Record<string, string | number | boolean>) => void;
} & WithStyles<typeof styles>;

const EditProfile: React.FC<Props> = (props) => {
  const { classes, user, onSave } = props;

  const [formData, setFormData] = useState<FormData>(() => {
    let { phone } = user;

    if (phone && phone.length === 10) {
      phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, (...match: string[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [fullMatch, g1, g2, g3] = match;

        return `${g1}-${g2}-${g3}`;
      });
    }

    return {
      ...user, phone, oldEmail: user.email, oldPhone: user.phone, pass_word: '', confirm: '',
    };
  });

  const [inputErrors, setInputErrors] = useState({
    username: '', firstName: '', lastName: '', phone: '', email: '', pass_word: '',
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value, checked } = e.currentTarget;
    setFormData((prevState) => ({ ...prevState, [name]: isCheckable(name) ? Number(checked) : value }));
    setInputErrors((prevState) => ({ ...prevState, [name]: '' }));
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (formData.pass_word !== formData.confirm) {
      const pass_word = 'Password doesn\'t match.';

      return setInputErrors((prevState) => ({ ...prevState, pass_word }));
    }

    const {
      error,
      value,
    } = validationSchema.validate(formData, { abortEarly: false, stripUnknown: true });

    if (!error) {
      const { confirm, pass_word, ...userInput } = value;

      onSave({
        ...userInput,
        ...(pass_word && { pass_word }),
      });
    }

    if (error && error.isJoi) {
      const { details } = error;
      const errorsEntries = details.map(({ message, path }) => [path[0], message]);
      setInputErrors(Object.fromEntries(errorsEntries));
    }
  }

  return (
    <div>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled
              // variant="filled"
              margin="normal"
              id="username"
              label="Username"
              name="username"
              value={formData.username}
              error={Boolean(inputErrors.username)}
              helperText={inputErrors.username ? 'Staying username doesn’t match firstname.lastname format' : null}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              // variant="filled"
              margin="normal"
              id="firstName"
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={Boolean(inputErrors.firstName)}
              helperText={inputErrors.firstName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              // variant="filled"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={Boolean(inputErrors.lastName)}
              helperText={inputErrors.lastName}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="999-999-99999"
              onChange={handleChange}
              value={formData.phone}
            >
              {(inputProps: InputState) => (
                <TextField
                  {...inputProps}
                  required
                  fullWidth
                  // variant="filled"
                  type="tel"
                  margin="normal"
                  id="phone"
                  label="Phone"
                  name="phone"
                  error={Boolean(inputErrors.phone)}
                  helperText={inputErrors.phone}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              // variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(inputErrors.email)}
              helperText={inputErrors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  color="secondary"
                  name="send_sms"
                  checked={!!formData.send_sms}
                  onChange={handleChange}
                />
              )}
              label="Send SMS"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  color="secondary"
                  name="send_email"
                  checked={!!formData.send_email}
                  onChange={handleChange}
                />
              )}
              label="Send Email"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              // variant="filled"
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="pass_word"
              type="password"
              value={formData.pass_word}
              onChange={handleChange}
              error={Boolean(inputErrors.pass_word)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              // variant="filled"
              margin="normal"
              required
              fullWidth
              id="confirm"
              label="Confirm Password"
              name="confirm"
              type="password"
              value={formData.confirm}
              onChange={handleChange}
              error={Boolean(inputErrors.pass_word)}
              helperText={inputErrors.pass_word}
            />
          </Grid>
        </Grid>
        <div className={classes.cardActions}>
          <Grid container justify="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default React.memo(EditProfile);
