import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pointer: {
    cursor: 'pointer',
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 25,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  avatar: {
    color: 'white',
  },
  saveButton: {
    color: '#FFFFFF',
    minWidth: 100,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    backgroundColor: '#DB5C0E',
  },
  rightSide: {
    minHeight: '100%',
    padding: '8px 30px 40px 30px',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
  },
  tabsContent: {
    height: '100%',
  },
  contentWrapper: {
    display: 'flex',
    '& > div': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  tabText: {
    minWidth: 'fit-content',
    textTransform: 'none',
    color: '#DB5C0E',
    fontSize: 16,
  },
  tabs: {
    borderBottom: 'rgb(219, 92, 14)',
  },
  linkButton: {
    padding: '10px 20px 10px 20px',
    alignItems: 'center',
    backgroundColor: theme.appPalette.orange,
    borderRadius: '15px',
    border: 'none',
  },
  downloadLink: {
    marginLeft: '10px',
    fontWeight: 'bold',
    color: 'white',
    textDecoration: 'none',
    lineHeight: '1.4',
  },
  btnStyle: {
    transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',
    backgroundColor: '#DB5C0E',
    color: 'white',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
