import React, { useEffect, useState } from 'react';
import {
RouteComponentProps,
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField } from '@material-ui/core';
import useStyles from './styles';
import { Builder, BuilderPM } from '../../redux/types/builders';
import CustomButton from '../../components/CustomButton';

type Props = {
  loadingBuilders?: boolean;
  isAdmin: boolean;
  PMs: BuilderPM[];
  builders: Builder[];
  loadBuilders: () => void;
  loadingPMs?: boolean;
  loadPMs: (id: number) => void;
  authPM: (agent_username: string) => void;
} & RouteComponentProps;


const SelectPM: React.FC<Props> = (props) => {
  const {
    loadingBuilders,
    isAdmin,
    builders,
    loadBuilders,
    loadingPMs,
    loadPMs,
    PMs,
    authPM,
  } = props;

  const [builder, setBuilder] = useState<string>('');
  const [PM, setPM] = useState<string>('');
  const classes = useStyles();


  const [selectedBuilder, setSelectedBuilder] = useState<any>('');

  useEffect(() => {
    loadBuilders()
  }, [loadBuilders])

  useEffect(() => {
    const selected = builders.find(b => b.name === builder)
    setSelectedBuilder(selected)
    if (selectedBuilder) {
      loadPMs(selectedBuilder?.builder_id)
    }
  }, [builder, selectedBuilder, builders, loadPMs])

  const handleBuilderChange = (e: any, chosenSelect: any | null) => {
    setBuilder(chosenSelect);
  }

  const handlePmChange = (e: any, chosenSelect: any | null) => {
    setPM(chosenSelect);
  }

  const signInAsPM = () => {
    authPM(PM.slice(0, PM.length-5))
  }

  const listBuilders = builders.map(({ name }) => name);

  // const listPMs = PMs.map(({username}) => username);

  const listPMs = PMs.map((PM) => `${PM.username} (${PM.__agent__?.is_project_manager ? 'PM' : 'SS'})`);
  return (
    <>
      {isAdmin && (
        <Grid container justify='center' alignItems='center' direction='column' className={classes.content}>
          <Grid item container xs={12} sm={4} justify='center' alignContent='flex-start' className={classes.container}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                options={listBuilders}
                id="multiple-limit-tags"
                getOptionLabel={(option: any) => option}
                noOptionsText="No builders were found"
                onChange={handleBuilderChange}
                className={classes.input}
                loading={loadingBuilders}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={'Select builder'}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingBuilders ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}

                  />
                )}
              />
            </Grid>
            {selectedBuilder && !loadingPMs && PMs.length > 0 && (
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  options={listPMs}
                  id="multiple-limit-tags"
                  getOptionLabel={(option: any) => option}
                  noOptionsText="No PMs were found"
                  onChange={handlePmChange}
                  className={classes.input}
                  loading={loadingPMs}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label={'Select PM'}
                      fullWidth
                      onChange={handlePmChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingPMs ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}

                    />
                  )}
                />
              </Grid>

            )
            }
            {
              PM && !loadingPMs && (
                <Grid container item justify='center' alignItems='center' direction='column' className={classes.buttonContainer}>
                  <Grid item xs={12} sm={12}>
                    <CustomButton variant='orange' onClick={signInAsPM}>
                      Sign in as {PM.slice(0, PM.length-5)}
                    </CustomButton>
                  </Grid>
                </Grid>

              )
            }


          </Grid>
        </Grid>
      )
      }

    </>
  );
};

export default React.memo(SelectPM);
