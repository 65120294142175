import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Button,
  Paper,
  StepLabel,
  Box,
  StepConnector,
  withStyles,
  Stepper,
  Step,
  TextField,
} from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import useStyles from './styles';
import { navTo } from '../../browserHsitory';
import { Community, WeeklyTourHours } from '../../redux/types/communities';
import {
  automationsSchema,
  communityInputSchema,
} from './CommunityInputValidation';
import CommunityInfo from './CommunityInfo/index';
// import CommunityPrivacyInfo from './CommunityPrivacyInfo/index';
import CommunityIntegrations from './CommunityIntegrations/index';
import PropertyTourHours from '../TourHours';
import CustomButton from '../CustomButton';
import {
  ValidationErrors,
  beautifyErrors,
  identicalCodesSchema,
} from '../../utils/helpers';
import Staff from '../Staff';
import { Team } from '../../redux/types/teams';
import { Agent } from '../../redux/types/agents';
import { Builder } from '../../redux/types/builders';

const defaultCommunity = {
  id: 0,
  name: '',
  state: '',
  city: '',
  external_community_id: '',
  zip: '',
  created_on: '',
  created_by: '',
  modified_on: '',
  modified_by: '',
  builder_id: null,
  builderName: '',
  status: '',
  sales_agent: '',
  community_url: '',
  floor_plan_url: '',
  virtual_tour_url: '',
  additional_notes: '',
  privacy_additional_notes: '',
  privacy_logo: '',
  communityTourHours: null,
};

type Props = {
  communitySave: (community: Community) => void;
  successfullySaved: boolean;
  cancelSuccessfullySave: () => void;
  builderName: string;
  builderId: number;
  loadTeams: () => void
  loadTeamsByBuilder: (builderId: number) => void;
  loadAvailableAgents: (builderId: number) => void;
  loadEnterpriseBuilders: (enterpriseId: number) => void;
  teams: Team[];
  enterpriseId: number | null;
  enterpriseBuilders: Builder[];
} & RouteComponentProps;

const Connector = withStyles({
  alternativeLabel: { top: 9 },
  active: { '& $line': { borderColor: '#DB5C0E' } },
  completed: { '& $line': { borderColor: '#DB5C0E' } },
  line: {
    borderTopWidth: 2,
  },
  vertical: {
    marginLeft: 9,
  },
  lineVertical: {
    borderLeftWidth: 2,
  },
})(StepConnector);

const CommunityAddForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    history,
    communitySave,
    cancelSuccessfullySave,
    successfullySaved,
    builderName,
    builderId,
    loadTeams,
    loadTeamsByBuilder,
    loadAvailableAgents,
    loadEnterpriseBuilders,
    teams,
    enterpriseId,
    enterpriseBuilders,
  } = props;
  const [community, setCommunity] = useState<Community>(defaultCommunity);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [submit, setSubmit] = useState<boolean>(false);
  const [communityTourHours, setCommunityTourHours] = useState<
  WeeklyTourHours[] | null
  >(null);
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Community>
  >({});
  const [selectedBuilder, setSelectedBuilder] = useState<number | null>(null);

  const handleSaveCommunity = useCallback(async () => {
    try {
      const validCommunity = communityInputSchema.validateSync(
        community,
        { abortEarly: false },
      ) as Community;
      await communitySave({ ...validCommunity });
      navTo('/communities');
    } catch (errors) {
      console.log(errors);
    }
  }, [community, communitySave]);

  const steps = [
    'Community Info',
    'Tour Hours',
    'Staff',
    'Integrations',
    'Automations',
    // 'Privacy Info',
  ];

  useEffect(() => {
    if (!enterpriseId) {
      loadTeams();
    }

    if (enterpriseId && selectedBuilder) {
      loadTeamsByBuilder(selectedBuilder);
    }
  }, [loadTeams, loadTeamsByBuilder, enterpriseId, selectedBuilder]);

  useEffect(() => {
    if (!enterpriseId) {
      loadAvailableAgents(builderId);
    }

    if (enterpriseId && selectedBuilder) {
      loadAvailableAgents(selectedBuilder);
    }
  }, [loadAvailableAgents, builderId, selectedBuilder, enterpriseId]);

  useEffect(() => {
    setCommunity((prevState) => ({ ...prevState, communityTourHours }));
  }, [communityTourHours]);

  useEffect(() => {
    if (successfullySaved) {
      navTo('/communities');
      cancelSuccessfullySave();
    }
  }, [successfullySaved, cancelSuccessfullySave]);

  useEffect(() => {
    if (submit) {
      handleSaveCommunity();
    }
  }, [submit, handleSaveCommunity]);

  useEffect(() => {
    if (enterpriseId) {
      loadEnterpriseBuilders(enterpriseId);
    }
  }, [loadEnterpriseBuilders, enterpriseId]);

  useEffect(() => {
    if (!enterpriseId) {
      setSelectedBuilder(builderId);
      setCommunity((prevState) => ({
        ...prevState,
        builder_id: builderId,
        builderName,
      }));
    }
  }, [enterpriseId, builderId, builderName]);

  const handleCommunityInfo = (communityInfo: Community) => {
    setCommunity(communityInfo);
    setPageIndex(pageIndex + 1);
  };

  const handleTourHours = (weeklyTourHours: WeeklyTourHours[] | null) => {
    setCommunityTourHours(weeklyTourHours);
  };

  const handleCommunityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const digitsOnlyFields = [
      'community_backup_code',
      'trade_code',
      'sales_code',
    ];

    if (digitsOnlyFields.includes(name) && Number.isNaN(Number(value))) {
      return;
    }

    setCommunity({ ...community, [name]: value });
  };

  // const handlePrivacyInfo = (privacyNotes: string, privacyLogo: string) => {
  //   setCommunity({
  //     ...community,
  //     privacy_additional_notes: privacyNotes,
  //     privacy_logo: privacyLogo,
  //   });
  //   setSubmit(true);
  // };

  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const handleAutomations = () => {
    try {
      const codes = {
        community_backup_code: community.community_backup_code ?? '',
        trade_code: community.trade_code ?? '',
        sales_code: community.sales_code ?? '',
      };

      automationsSchema.validateSync(community, { abortEarly: false });
      identicalCodesSchema.validateSync(codes, { abortEarly: false });

      setValidationErrors({});
      // setPageIndex(pageIndex + 1);
      setSubmit(true);
    } catch (errors) {
      setValidationErrors(beautifyErrors<Community>(errors));
    }
  };

  const handleAddUsers = (agents: Agent[], teamIds?: number[]) => {
    setCommunity({
      ...community,
      agents,
      teams: teamIds ?? community.teams,
    });
  };

  const handleSelectBuilder = (selectedBuilderId: number) => {
    const selected = enterpriseBuilders.find(
      ({ builder_id }) => builder_id === selectedBuilderId,
    );

    if (selected) {
      setSelectedBuilder(selectedBuilderId);
      setCommunity((prevState) => ({
        ...prevState,
        builder_id: selectedBuilderId,
      }));
    }
  };

  return (
    <>
      <div>
        <Grid container justify="space-between" style={{ marginBottom: 25 }}>
          <Grid item>
            <Button variant="contained" onClick={() => history.goBack()}>
              <ArrowLeft />
              {' GoBack'}
            </Button>
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <div className={classes.headerText}>
            <span>Community Wizard</span>
          </div>
          <Box style={{ width: '60%', marginLeft: '20%' }}>
            <Stepper
              alternativeLabel
              className={classes.stepper}
              activeStep={pageIndex}
              connector={<Connector />}
            >
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.stepIcon,
                          completed: classes.stepIcon,
                        },
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          {pageIndex === 0 && (
          <CommunityInfo
            defaultCommunity={community}
            handleChanges={handleCommunityInfo}
            enterpriseId={enterpriseId}
            builderId={enterpriseId ? selectedBuilder : builderId}
            builderName={builderName}
            loadingEnterpriseBuilders
            enterpriseBuilders={enterpriseBuilders}
            handleSelectBuilder={handleSelectBuilder}
            selectedBuilder={selectedBuilder}
          />
          )}
          {pageIndex === 1 && (
            <>
              <div className={classes.headerText}>
                <span>Tour Hours</span>
              </div>
              <Grid container className={classes.inputForm}>
                <PropertyTourHours
                  variant="community"
                  handleTourHours={handleTourHours}
                  handleNextPage={() => {
                    setPageIndex(pageIndex + 1);
                  }}
                />
              </Grid>
              <div className={classes.navButtons}>
                <CustomButton variant="white" onClick={handlePreviousPage}>
                  Previous
                </CustomButton>
                <CustomButton
                  variant="orange"
                  onClick={() => {
                    setPageIndex(pageIndex + 1);
                  }}
                >
                  Next
                </CustomButton>
              </div>
            </>
          )}
          {pageIndex === 2 && (
            <>
              <div className={classes.headerText}>
                <span>Add Users & Teams</span>
              </div>
              <Staff
                handlePreviousPage={handlePreviousPage}
                handleNextPage={() => setPageIndex(pageIndex + 1)}
                handleAddUsers={handleAddUsers}
                teams={teams}
                agents={community?.agents}
              />
              <div className={classes.navButtons}>
                <CustomButton variant="white" onClick={handlePreviousPage}>
                  Previous
                </CustomButton>
                <CustomButton
                  variant="orange"
                  onClick={() => {
                    setPageIndex(pageIndex + 1);
                  }}
                >
                  Next
                </CustomButton>
              </div>
            </>
          )}
          {pageIndex === 3 && (
            <>
              <div className={classes.headerText}>
                <span>Integrations</span>
              </div>
              <Grid container spacing={4} className={classes.inputForm}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="lasso_community_project_id"
                    name="lasso_community_project_id"
                    label="Lasso Community Project ID"
                    value={community?.lasso_community_project_id ?? ''}
                    onChange={handleCommunityChange}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="novihome_integration"
                    name="novihome_integration"
                    label="NoviHome Integration"
                    value={community?.novihome_integration ?? ''}
                    onChange={handleCommunityChange}
                  />
                </Grid>
              </Grid>
              <div className={classes.navButtons}>
                <CustomButton variant="white" onClick={handlePreviousPage}>
                  Previous
                </CustomButton>
                <CustomButton
                  variant="orange"
                  onClick={() => {
                    setPageIndex(pageIndex + 1);
                  }}
                >
                  Next
                </CustomButton>
              </div>
            </>
          )}
          {pageIndex === 4 && (
            <>
              <div className={classes.headerText}>
                <p>Automations</p>
              </div>
              <Grid container spacing={4} className={classes.inputForm}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="community_backup_code"
                    name="community_backup_code"
                    label="Community Master Code"
                    value={community?.community_backup_code ?? ''}
                    onChange={handleCommunityChange}
                    error={Boolean(validationErrors.community_backup_code)}
                    helperText={validationErrors.community_backup_code}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="trade_code"
                    name="trade_code"
                    label="Trade Code"
                    value={community?.trade_code ?? ''}
                    onChange={handleCommunityChange}
                    error={Boolean(validationErrors.trade_code)}
                    helperText={validationErrors.trade_code}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="sales_code"
                    name="sales_code"
                    label="Sales Code"
                    value={community?.sales_code ?? ''}
                    onChange={handleCommunityChange}
                    error={Boolean(validationErrors.sales_code)}
                    helperText={validationErrors.sales_code}
                  />
                </Grid>
              </Grid>
              <div className={classes.navButtons}>
                <CustomButton variant="white" onClick={handlePreviousPage}>
                  Previous
                </CustomButton>
                <CustomButton variant="orange" onClick={handleAutomations}>
                  Submit
                </CustomButton>
              </div>
            </>
          )}
          {/* {pageIndex === 5 && (
            <CommunityPrivacyInfo
              handleChanges={handlePrivacyInfo}
              previousPage={handlePreviousPage}
            />
          )} */}
          {pageIndex === 6 && (
            <CommunityIntegrations
              previousPage={handlePreviousPage}
              saveCommunity={handleSaveCommunity}
            />
          )}
        </Paper>
      </div>
    </>
  );
};

export default CommunityAddForm;
