import { createSelector } from 'reselect';
import { AppState } from '../store';
import { AuthState } from '../types/auth';

export type UserData = Pick<
AuthState,
'builderId' | 'builder' | 'firstName' | 'lastName' | 'username' | 'isPM' | 'isAdmin' | 'isEnterprise' | 'phone' | 'email' | 'send_sms' | 'send_email' | 'userRoleId' | 'isRental'
>;

export const selectAuthData = (state: AppState) => state.auth;
export const selectLoading = (state: AuthState) => state.loading;
export const selectPmStatus = (state: AuthState) => state.isPM;
export const selectAdminStatus = (state: AuthState) => state.isAdmin;
export const selectEnterpriseStatus = (state: AuthState) => state.isEnterprise;
export const selectUsername = (state: AuthState) => state.username;
export const selectBuilderName = (state: AuthState) => state.builder;
export const selectIsRental = (state: AuthState) => state.isRental;
const selectBuilderId = (state: AuthState) => state.builderId;
const selectEnterpriseId = (state: AuthState) => state.enterpriseId;

export const selectUserData = createSelector(
  selectAuthData,
  ({
    builderId, builder, isRental, firstName, lastName, username, isPM, isAdmin, phone, email, send_sms, send_email, isEnterprise, userRoleId,
  }): UserData => ({
    builderId,
    builder,
    isRental,
    firstName,
    lastName,
    username,
    isPM,
    isAdmin,
    isEnterprise,
    phone,
    email,
    send_sms,
    send_email,
    userRoleId,
  }),
);

export const selectOauthClients = createSelector(selectAuthData, (auth) => auth.oauthClients);

export const selectAuthRequestStatus = createSelector(selectAuthData, selectLoading);

export const selectIsPM = createSelector(selectAuthData, selectPmStatus);

export const selectIsAdmin = createSelector(selectAuthData, selectAdminStatus);

export const selectIsEnterprise = createSelector(selectAuthData, selectEnterpriseStatus);

export const getBuilderId = createSelector(selectAuthData, selectBuilderId);

export const getEnterpriseId = createSelector(selectAuthData, selectEnterpriseId);

export const getBuilderName = createSelector(selectAuthData, selectBuilderName);

export const getIsRental = createSelector(selectAuthData, selectIsRental);

export const getUsername = createSelector(selectAuthData, selectUsername);
