export const SearchSortActionTypes = {
  CHANGE_CUSTOMER: 'CHANGE_CUSTOMER',
  CHANGE_VISIT: 'CHANGE_VISIT',
  CHANGE_VISITS_REPORT: 'CHANGE_VISITS_REPORT',
  CHANGE_SCHEDULED_VISIT: 'CHANGE_SCHEDULED_VISIT',
  CHANGE_PROPERTY: 'CHANGE_PROPERTY',
  CHANGE_AGENT: 'CHANGE_AGENT',
  CHANGE_COMMUNITY: 'CHANGE_COMMUNITY',
  CHANGE_TEAM: 'CHANGE_TEAM',
  CHANGE_OFFER: 'CHANGE_OFFER',
  CHANGE_VISITS_AFTER_TOUR_HOURS: 'CHANGE_VISITS_AFTER_TOUR_HOURS',
  CHANGE_WEBHOOK: 'CHANGE_WEBHOOK',
  RESET_STATE: 'RESET_STATE',
} as const;
