/* eslint-disable @typescript-eslint/no-use-before-define */
import format from 'date-fns/format';
import * as Sentry from '@sentry/browser';
import { parse, parseISO } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export const defaultDateFormat = 'MM/dd/yyyy';
export const timeFormat = 'HH:mm';
export const defaultDateTimeFormat = `${defaultDateFormat} ${timeFormat}`;

export function formatDate(
  dateString: string | Date | null,
  formatString: string = defaultDateFormat,
): string {
  if (!dateString) {
    return '';
  }

  try {
    return format(new Date(dateString), formatString);
  } catch (e) {
    return '';
  }
}

export const listFormatDate = (dateString: string): string => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString)
      .toLocaleString()
      .split(',')
      .join(' /');
  } catch (e) {
    return '';
  }
};

export const estToUtc = (date?: Date | null, timezone?: number, dateTimeFormat: string = defaultDateTimeFormat) => {
  if (!date) {
    return '';
  }
  if (!timezone) {
    return format(date, dateTimeFormat);
  }
  const timeZone = findTimeZome(timezone);
  return format(zonedTimeToUtc(date, timeZone), dateTimeFormat);
};

export const formatToESTDate = (
  dateString: string | Date | null,
  timezoneAbbr: number,
  timezoneValue?: string,
  dateTimeFormat: string = 'yyyy-MM-dd h:mm a',
) => {
  if (!dateString || dateString === '0000-00-00 00:00:00') {
    return '';
  }

  try {
    const timeZone = findTimeZome(timezoneAbbr);
    const date = typeof dateString === 'string' ? parseISO(dateString) : dateString;
    const zonedDate = utcToZonedTime(date, timeZone);

    const formattedDateTime = format(zonedDate, dateTimeFormat);

    return [formattedDateTime, timezoneValue].join('\u00a0');
  } catch (err) {
    Sentry.captureException(err);
    return '';
  }
};

export const toUTCDate = (
  dateString: string,
) => {
  if (!dateString) {
    return '';
  }
  return new Date(`${dateString}Z`);
};


const findTimeZome = (tz: number) => {
  const zones: { [key: number]: string } = {
    51: 'America/Denver',
    52: 'America/New_York',
    53: 'America/Chicago',
    54: 'America/Los_Angeles',
  };

  return zones[tz] || 'America/New_York';
};

export const isValidDate = (date: Date): boolean => date.toString() !== 'Invalid Date';

export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h.split(' ');
  let [hour, minute] = time.split(':');
  hour = parseInt(hour, 10);
  minute = parseInt(minute, 10);

  if (hour === 12) {
    hour = 0;
  }

  if (modifier === 'PM') {
    hour += 12;
  }

  return { hour, minute };
};

export const convertTime24To12 = (hour?: number, minute?: number) => {
  if (!hour && !minute) {
    return;
  }

  return format(parse(`${hour}:${minute}`, 'HH:mm', new Date()), 'h:mm a');
};

const toMillis = (time: { hour: number; minute: number }) => time.hour * 60 * 60 * 1000 + time.minute * 60 * 1000;

export const compareTime = (
  time1: { hour: number; minute: number },
  time2: { hour: number; minute: number },
  lessThanOrEqual: boolean,
) => {
  const millis1 = toMillis(time1);
  const millis2 = toMillis(time2);
  const result = lessThanOrEqual ? millis1 <= millis2 : millis1 < millis2;

  return result;
};

export const startOfDay = (date: Date) => {
  if (!date) {
    return date;
  }
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const endOfDay = (date: Date) => {
  if (!date) {
    return date;
  }
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};
