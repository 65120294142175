import { createStyles } from '@material-ui/core';

export default () => createStyles({
  cardActions: {
    marginTop: 45,
  },
  // password: {
  //   '& > label': {
  //     color: 'red',
  //     top: -5,
  //     ':focus': {
  //       top: 2
  //     }
  //   }
  // }
});
