import { ErrorMessage, ValueOf } from '../../types/main';
import * as AuthActions from '../actions/auth';

export const AUTH_USER_REQUEST = 'AUTH_USER_REQUEST';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';

export const GET_OAUTH_CLIENTS_REQUEST = 'GET_OAUTH_CLIENTS_REQUEST';
export const GET_OAUTH_CLIENTS_SUCCESS = 'GET_OAUTH_CLIENTS_SUCCESS';
export const GET_OAUTH_CLIENTS_ERROR = 'GET_OAUTH_CLIENTS_ERROR';

export const REMOVE_OAUTH_CLIENTS_REQUEST = 'REMOVE_OAUTH_CLIENTS_REQUEST';
export const REMOVE_OAUTH_CLIENTS_SUCCESS = 'REMOVE_OAUTH_CLIENTS_SUCCESS';
export const REMOVE_OAUTH_CLIENTS_ERROR = 'REMOVE_OAUTH_CLIENTS_ERROR';

export const CREATE_OAUTH_CLIENTS_REQUEST = 'CREATE_OAUTH_CLIENTS_REQUEST';
export const CREATE_OAUTH_CLIENTS_SUCCESS = 'CREATE_OAUTH_CLIENTS_SUCCESS';
export const CREATE_OAUTH_CLIENTS_ERROR = 'CREATE_OAUTH_CLIENTS_ERROR';

export const AUTH_PM_REQUEST = 'AUTH_PM_REQUEST';
export const AUTH_ENTERPRISE_USER_REQUEST = 'AUTH_ENTERPRISE_USER_REQUEST';

export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const AUTH_ADMIN_SUCCESS = 'AUTH_ADMIN_SUCCESS';

export type AuthActionsTypes = ReturnType<ValueOf<typeof AuthActions>>;

export interface AuthRequest {
  username: string
  password: string
}

export interface AuthPMRequest {
  agent_username: string
}

export interface AuthEnterpriseUserRequest {
  username: string
  builder_id: number
}

export interface AuthSuccess {
  token: string
  username: string
  firstName: string
  lastName: string
  builderId: number
  enterpriseId: number | null
  builder: string
  isRental: boolean
  email: string
  phone: string
  send_email: boolean
  send_sms: boolean
  isPM: boolean
  isAdmin: boolean
  isEnterprise: boolean
  userRoleId: number
}

export interface AuthState {
  token: string
  username: string
  firstName: string
  lastName: string
  builderId: number
  enterpriseId: number | null
  builder: string
  isRental: boolean
  isPM: boolean
  isAdmin: boolean
  isEnterprise: boolean
  email: string
  phone: string
  send_email: boolean
  send_sms: boolean
  loading: boolean
  error: ErrorMessage['message']
  oauthClients: OauthClient[]
  userRoleId: number
}

export interface OauthClient {
  id: number
  client_secret: string
  client_id: string
  redirect_uri: string | null
  user_id: string
  created_at: string
  updated_at: string
}

export interface ClientDelete {
  clientId: string
  clientSecret: string
}
