/* eslint-disable @typescript-eslint/camelcase */
import Joi from '@hapi/joi';

export const username = Joi.string()
  .label('User name')
  .min(1)
  .max(250)
  .required();

export const firstname = Joi.string()
  .label('First name')
  .min(1)
  .max(250)
  .required();

export const lastname = Joi.string()
  .label('Last name')
  .min(1)
  .max(250)
  .required();

export const email = Joi.string()
  .label('Email')
  .email({ minDomainSegments: 2, tlds: false })
  .required();

export const notification_email = Joi.any()
  .label('Notification email')
  .allow('');


export const phone = Joi.string()
  .label('Phone')
  .regex(/^\d{3}-\d{3}-\d{4}$/, 'required')
  .required();

const send_email = Joi.number().required();
const send_sms = Joi.number().required();
const roles = Joi.object().keys({ Label: Joi.string(), id: Joi.number(), description: Joi.string() });
const oldPhone = Joi.string().allow('');
const oldEmail = Joi.string().allow('');
const pass_word = Joi.string().label('Password').allow(null).required();
const confirm_password = Joi.any().valid(Joi.ref('pass_word'));

export const agentValidationSchema = Joi.object({
  username,
  firstname,
  lastname,
  email,
  phone,
  send_email,
  notification_email,
  send_sms,
  roles,
  oldPhone,
  oldEmail,
  pass_word,
  confirm_password,
  builder_id: Joi.number().optional(),
});
