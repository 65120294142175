import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import TextField from '@material-ui/core/TextField';
import InputPriceWrapper from '../InputPriceWrapper';
import {
  formatDate, defaultDateTimeFormat, startOfDay, endOfDay,
} from '../../utils/date'; // theme css file

const useStyles = makeStyles(() => ({
  container: {
    width: '270px',
  },
}));

interface DateRangeSelection {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
  title: string;
}

interface DateRangeProps {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  onChange: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

export default function DateRange(props: DateRangeProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectionRange, setSelectionRange] = useState<DateRangeSelection>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
    title: '⚡ Live Tail',
  });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    const startDate = startOfDay(ranges.selection.startDate);
    const endDate = endOfDay(ranges.selection.endDate);
    setSelectionRange({
      startDate,
      endDate,
      key: 'selection',
      title: selection.key === 'live_tail' ? '⚡ Live Tail' : `${formatDate(selection.startDate, defaultDateTimeFormat)} - ${formatDate(selection.endDate, defaultDateTimeFormat)}`,
    });
    props.onChange(startDate, endDate);
  };


  const open = Boolean(anchorEl);

  return (
    <div className={classes.container}>
      <InputPriceWrapper>
        <TextField
          fullWidth
          margin="normal"
          id="price"
          name="Date"
          onClick={handleClick}
          value={selectionRange.title}
        />
      </InputPriceWrapper>
      <Popover
        id="simple-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          ranges={[selectionRange]}
          maxDate={endOfDay(new Date())}
          onChange={handleSelect}
          rangeColors={['#DB5C0E']}
          staticRanges={[
            {
              range: () => ({
                startDate: undefined,
                endDate: undefined,
                key: 'live_tail',
              }),
              label: '⚡ Live Tail',
              isSelected: () => selectionRange.title === '⚡ Live Tail',
            },
            ...defaultStaticRanges,
          ]}
        />
      </Popover>
    </div>
  );
}
