export const ALL_GROUPS_FOR_AGENT = [
  {
    Label: 'Program Manager',
    description:
      'Full permissions to add/edit staff, setup properties, settings for your company.',
    id: 4
  },
  {
    Label: 'Sales Staff',
    description:
      'Agents or OSCs that need to receive Visit alerts AND/OR see property and visit details in the NterNow dashboard. It also has NterNow Staff App access.',
    id: 2
  },
  {
    Label: 'Other Staff',
    description:
      'Non-lead agents and managers, field staff, other employees who only need access to homes via NterNow Staff Mobile App',
    id: 1
  },
  {
    Label: 'Trade Contractor',
    description:
      'Traders who need access to properties through Staff Mobile App',
    id: 7
  },
];
