import { connect } from 'react-redux';
import SelectPM from './SelectPM';
import { selectAdminStatus } from '../../redux/selectors/auth';
import { selectBuilders, selectBuildersRequestStatus, selectBuilderPMsRequestStatus, selectBuilderPMs } from '../../redux/selectors/builders';
import { AppState } from '../../redux/store';
import { buildersRequest } from '../../redux/actions/builders';
import { authPMRequest } from '../../redux/actions/auth';
import { buildersPMsRequest } from '../../redux/actions/builderPMs';

const mapStateToProps = (state: AppState) => ({
    builders: selectBuilders(state),
    loadingBuilders: selectBuildersRequestStatus(state),
    isAdmin: selectAdminStatus(state.auth),
    PMs: selectBuilderPMs(state),
    loadingPMs: selectBuilderPMsRequestStatus(state),
});

const mapDispatchToProps = {
    loadBuilders: buildersRequest,
    loadPMs: buildersPMsRequest,
    authPM: authPMRequest,
  };

export default 
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SelectPM);

