/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable lines-between-class-members */
import React, { useEffect, useState } from 'react';
import { QRCode as QR } from 'react-qrcode-logo';

import {
  Typography,
  Grid,
  Button,
  Tabs,
  Tab,
  TextField,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { ArrowLeft } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDispatch, useSelector } from 'react-redux';
import TimeLineItem from '../../components/TimeLineItem';
import TimeLine from '../../components/TimeLine';
import useStyles from './styles';
import {
  Community,
  QRCode,
  SelectedCommunity,
  WeeklyTourHours,
} from '../../redux/types/communities';
import { State } from '../../redux/types/states';
import { COMMUNITIES } from '../../constants/sortState';
import { clearStorage } from '../../services/storage';
import CommunityInfoCard from '../../components/CommynityInfoCard';
import { Property } from '../../redux/types/properties';
import PropertyTourHours from '../../components/TourHours';
import appConfig from '../../config';
import { ValidationErrors } from '../../utils/helpers';
import Staff from '../../components/Staff';
import { Agent } from '../../redux/types/agents';
import { Team } from '../../redux/types/teams';
import { selectBuilderSettings } from '../../redux/selectors/builders';
import { builderSettingsRequest } from '../../redux/actions/builders';
import { getBuilderId } from '../../redux/selectors/auth';

const defaultCommunity = {
  id: 0,
  state: '',
  city: '',
  external_community_id: '',
  zip: '',
  created_on: '',
  created_by: '',
  modified_on: '',
  modified_by: '',
  builder_id: null,
  builderName: '',
  status: '',
};

type Props = {
  loadCommunityDetails: (communityId: number) => void;
  loadQRCodes: (communityId: number) => void;
  communitySave: (community: Community) => void;
  loadAgents: () => void;
  loadAvailableAgents: (builderId?: number) => void;
  loadTeams: () => void;
  loadTeamsByBuilder: (builderId: number) => void;
  cancelSuccessfullySave: () => void;
  deleteQRCode: (id: number, communityId: number) => void;
  saveQRCode: (
    communityId: number,
    QRCodeText: string,
    QRCodeName: string
  ) => void;
  communityDetails: SelectedCommunity | null;
  states: State[];
  agents: Agent[];
  teams: Team[];
  availableAgents: Agent[];
  QRCodes: QRCode[];
  successfullySaved: boolean;
  isPM: boolean;
  isEnterprise: boolean;
  builderId: number;
} & RouteComponentProps & { match: { params: { communityId: string } } };

const CommunityDetails: React.FC<Props> = ({
  history,
  match,
  loadCommunityDetails,
  communityDetails,
  loadQRCodes,
  loadAvailableAgents,
  loadTeams,
  loadTeamsByBuilder,
  teams,
  isEnterprise,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [community, setCommunity] = useState<Community>(defaultCommunity);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [communityTourHours, setCommunityTourHours] = useState<
  WeeklyTourHours[] | null
  >(null);
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Community>
  >({});
  const [assignedAgents, setAssignedAgents] = useState<Array<Agent>>([]);
  const [webflowUrl, setWebflowUrl] = useState<string>('');

  const builderId = useSelector(getBuilderId);
  const builderSettings = useSelector(selectBuilderSettings);

  useEffect(() => {
    setWebflowUrl(
      encodeURI(
        `${appConfig.WEBFLOW_URL}${builderSettings?.webflow_name
          || community.builderName}/community/${community.id}`,
      ),
    );
  }, [community, builderSettings]);

  useEffect(() => {
    dispatch(builderSettingsRequest(builderId));
  }, [dispatch, builderId]);


  useEffect(() => {
    if (!community.builder_id) {
      return;
    }

    if (!isEnterprise) {
      loadTeams();
    }

    if (isEnterprise) {
      loadTeamsByBuilder(community.builder_id);
    }

    loadAvailableAgents(community.builder_id);
  }, [
    loadTeams,
    loadAvailableAgents,
    loadTeamsByBuilder,
    isEnterprise,
    community.builder_id]);

  useEffect(() => {
    setCommunity((prevState) => ({ ...prevState, communityTourHours }));
  }, [communityTourHours]);

  useEffect(() => {
    loadCommunityDetails(Number(match.params.communityId));
    loadQRCodes(Number(match.params.communityId));
    return () => {
      const pageNameIndex = 1;
      const newPathName = history.location.pathname.split('/')[pageNameIndex];

      if (newPathName !== 'communities') {
        clearStorage(COMMUNITIES)();
      }
    };
  }, [
    match.params.communityId,
    loadCommunityDetails,
    history.location.pathname,
    loadQRCodes,
  ]);

  useEffect(() => {
    if (
      communityDetails
      && communityDetails.community
      && communityDetails.agents
    ) {
      const agents = communityDetails.agents.map((agent) => ({
        username: agent.username,
        firstname: agent.firstname,
        lastname: agent.lastname,
        phone: agent.phone,
        email: agent.email,
        is_project_manager: Boolean(agent.is_project_manager),
      }));

      setAssignedAgents(agents);
      setCommunity({
        ...communityDetails.community,
        agents,
        teams: communityDetails.teams,
      });
    }
  }, [communityDetails]);

  function handleChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setTabIndex(newTabIndex);
  }

  const handleTourHours = (weeklyTourHours: WeeklyTourHours[] | null) => {
    setCommunityTourHours(weeklyTourHours);
  };

  const handleValidationErrors = (errors: ValidationErrors<Community>) => {
    setValidationErrors(errors);
  };

  const handleCommunityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const digitsOnlyFields = [
      'community_backup_code',
      'trade_code',
      'sales_code',
    ];

    if (digitsOnlyFields.includes(name) && Number.isNaN(Number(value))) {
      return;
    }

    setCommunity({ ...community, [name]: value });
  };

  const handleAddUsers = (agents: Agent[], teamIds?: number[]) => {
    if (community) {
      setCommunity({
        ...community,
        agents,
        teams: teamIds ?? community?.teams,
      });
    }

    setAssignedAgents(agents);
  };

  const download = (e: any, qrname: string) => {
    const canvas: any = document.getElementById('QRCode');
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${qrname}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const validValues = (property: Property) => property.address_1
    && property.house_num
    && property.state
    && property.zipcode;

  return (
    <>
      {communityDetails && (
        <div>
          <Grid container justify="space-between" style={{ marginBottom: 25 }}>
            <Grid item>
              <Button variant="contained" onClick={() => history.goBack()}>
                <ArrowLeft />
                {' GoBack'}
              </Button>
            </Grid>
          </Grid>
          <div className={classes.contentWrapper}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <CommunityInfoCard
                  communityDetails={community}
                  handleValidationErrors={handleValidationErrors}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className={classes.rightSide}>
                  <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                  >
                    <Tab label="Tour Hours" className={classes.tabText} />
                    <Tab label="Staff" className={classes.tabText} />
                    <Tab label="Integrations" className={classes.tabText} />
                    <Tab label="Automations" className={classes.tabText} />
                    <Tab label="Properties" className={classes.tabText} />
                    <Tab label="QR Code" className={classes.tabText} />
                  </Tabs>
                  <div className={classes.tabsContent}>
                    {tabIndex === 0 && (
                      <Grid style={{ padding: '20px' }}>
                        <PropertyTourHours
                          variant="community"
                          handleTourHours={handleTourHours}
                          communityHours={
                            communityDetails.communityTourHours?.length
                              ? communityDetails.communityTourHours
                              : null
                          }
                        />
                      </Grid>
                    )}
                    {tabIndex === 1 && (
                      <>
                        <Staff
                          handleAddUsers={handleAddUsers}
                          agents={assignedAgents}
                          teamIds={community?.teams}
                          teams={teams}
                        />
                      </>
                    )}
                    {tabIndex === 2 && (
                      <>
                        <Grid container spacing={4}>
                          <Grid item xs>
                            <TextField
                              fullWidth
                              margin="normal"
                              id="lasso_community_project_id"
                              name="lasso_community_project_id"
                              label="Lasso Community Project ID"
                              value={
                                community?.lasso_community_project_id ?? ''
                              }
                              onChange={handleCommunityChange}
                            />
                          </Grid>
                          <Grid item xs>
                            <TextField
                              fullWidth
                              margin="normal"
                              id="novihome_integration"
                              name="novihome_integration"
                              label="NoviHome Integration"
                              value={community?.novihome_integration ?? ''}
                              onChange={handleCommunityChange}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {tabIndex === 3 && (
                      <>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              margin="normal"
                              id="community_backup_code"
                              name="community_backup_code"
                              label="Community Master Code"
                              value={community?.community_backup_code ?? ''}
                              onChange={handleCommunityChange}
                              error={Boolean(
                                validationErrors.community_backup_code,
                              )}
                              helperText={
                                validationErrors.community_backup_code
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              margin="normal"
                              id="trade_code"
                              name="trade_code"
                              label="Trade Code"
                              value={community?.trade_code ?? ''}
                              onChange={handleCommunityChange}
                              error={Boolean(validationErrors.trade_code)}
                              helperText={validationErrors.trade_code}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              margin="normal"
                              id="sales_code"
                              name="sales_code"
                              label="Sales Code"
                              value={community?.sales_code ?? ''}
                              onChange={handleCommunityChange}
                              error={Boolean(validationErrors.sales_code)}
                              helperText={validationErrors.sales_code}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {tabIndex === 4 && (
                      <TimeLine>
                        {communityDetails.communityProperties
                          .filter((property: Property) => validValues(property))
                          .map((property: Property) => (
                            <TimeLineItem
                              key={property.property_id}
                              alignItems="center"
                            >
                              <div>
                                <Typography>
                                  {validValues(property)
                                    ? `${property.house_num} ${property.address_1}, ${property.state} ${property.zipcode}`
                                    : ''}
                                </Typography>
                              </div>
                            </TimeLineItem>
                          ))}
                      </TimeLine>
                    )}
                    {tabIndex === 5 && (
                      <>
                        <Grid
                          container
                          direction="column"
                          style={{ padding: 25 }}
                          spacing={4}
                        >
                          <Grid item>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              Webflow QR Code:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <QR
                              id="QRCode"
                              value={webflowUrl}
                              ecLevel="L"
                              size={130}
                              quietZone={5}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              gutterBottom
                              // variant="h5"
                              component="h2"
                            >
                              <a
                                href={webflowUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '1.3rem' }}
                              >
                                {webflowUrl}
                              </a>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              size="medium"
                              variant="outlined"
                              className={classes.linkButton}
                              color="primary"
                              onClick={(e) => download(e, 'Webflow QR Code')}
                            >
                              <GetAppIcon
                                style={{ color: 'rgb(255,255,255)' }}
                              />
                              <div className={classes.downloadLink}>
                                <span>DOWNLOAD</span>
                                <br />
                                <span style={{ fontWeight: 300 }}>PNG</span>
                              </div>
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(CommunityDetails);
