import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { subMonths } from 'date-fns';
import { BatteryFull, Description, WatchLater } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { zonedTimeToUtc } from 'date-fns-tz';
import useStyles from './styles';
import { DateFilterName } from '../../components/SearchFilterElement/type';
import { ReactComponent as VisitsIcon } from '../../assets/visitsSideBarIcon.svg';
import { ReactComponent as InventoryIcon } from '../../assets/inventoryIcon.svg';
import { navTo } from '../../browserHsitory';
import { isValidDate } from '../../utils/date';
import { FilterPageKey, SetFilterPayload } from '../../redux/types/filter';
import { pagesWithDateFilter } from '../../components/SearchFilterElement/constants';
import useUrlLocation from '../../hooks/useUrlLocation';
import { selectVisitsReportFilters } from '../../redux/selectors/filter';
import { Builder } from '../../redux/types/builders';

type Props = {
  searchRequest?: () => void;
  setFilterState: (payload: SetFilterPayload) => void;
  loadEnterpriseBuilders: (enterpriseId: number) => void;
  enterpriseId: number | null;
  builderId: number;
  isEnterprise: boolean;
  enterpriseBuilders: Builder[];
  loadingEnterpriseBuilders: boolean;
};

const Reports: React.FC<Props> = (props) => {
  const {
    setFilterState,
    loadEnterpriseBuilders,
    loadingEnterpriseBuilders,
    isEnterprise,
    enterpriseId,
    builderId,
    enterpriseBuilders,
  } = props;
  const { startDate, endDate } = useSelector(selectVisitsReportFilters);
  const classes = useStyles();
  const path = useUrlLocation() as FilterPageKey;

  const [selectedBuilder, setSelectedBuilder] = useState<number | null>(
    builderId,
  );

  useEffect(() => {
    if (enterpriseId) {
      loadEnterpriseBuilders(enterpriseId);
    }
  }, [loadEnterpriseBuilders, enterpriseId]);

  useEffect(() => {
    if (!startDate) {
      setFilterState({
        type: 'reports',
        name: 'startDate',
        value: subMonths(zonedTimeToUtc(new Date(), 'UTC'), 1),
      });
    }

    if (!endDate) {
      setFilterState({
        type: 'reports',
        name: 'endDate',
        value: zonedTimeToUtc(new Date(), 'UTC'),
      });
    }
  }, [startDate, endDate, setFilterState]);

  const handleDateChange = (date: Date | null, name: DateFilterName) => {
    if (!date || (date && isValidDate(date))) {
      const page = pagesWithDateFilter.find((p) => p === path);

      if (page) {
        setFilterState({ type: page, name, value: date });
      }
    }
  };

  const handleSelectBuilder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedBuilderId = Number(e.target.value);

    setSelectedBuilder(selectedBuilderId);
  };

  return (
    <>
      <div className={classes.headerText}>
        <span>Reporting</span>
      </div>
      {isEnterprise && (
        <Grid container spacing={1} alignItems="center">
          <span className={classes.pickerText} style={{ marginRight: '43px' }}>
            Select Builder
          </span>
          <Grid item xs={2}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="state"
              name="state"
              label="Select Builder"
              value={isEnterprise && selectedBuilder}
              onChange={handleSelectBuilder}
              disabled={!isEnterprise && loadingEnterpriseBuilders}
            >
              <MenuItem value="" disabled>
                Select Builder
              </MenuItem>
              {enterpriseBuilders.map(({ builder_id: builderId, name }) => (
                <MenuItem key={builderId} value={builderId}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1} alignItems="center">
        <span className={classes.pickerText}>Reporting Period</span>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item>
            <KeyboardDatePicker
              size="small"
              autoOk
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="start-date-picker-inline"
              label="Start date"
              value={startDate}
              onChange={(date: Date | null) => handleDateChange(date, 'startDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={endDate}
              maxDateMessage="Start date cannot be later than end date"
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              size="small"
              autoOk
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="end-date-picker-inline"
              label="End date"
              value={endDate}
              onChange={(date: Date | null) => handleDateChange(date, 'endDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={new Date()}
              maxDateMessage="End date cannot be earlier than start date"
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <div className={classes.container}>
        <Grid container spacing={4} className={classes.cardsGrid}>
          <Grid item>
            <Card
              elevation={3}
              className={classes.card}
              onClick={() => {
                if (!startDate || !endDate) {
                  return;
                }

                navTo(
                  `reportPropertyTours?builderId=${selectedBuilder}&startDate=${new Date(
                    startDate,
                  ).toISOString()}&endDate=${new Date(endDate).toISOString()}`,
                );
              }}
            >
              <VisitsIcon className={classes.cardIcon} />
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Tours Per Property Report
                </Typography>
                <Typography className={classes.cardDescription} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              elevation={3}
              className={classes.card}
              onClick={() => {
                if (!startDate || !endDate) {
                  return;
                }

                navTo(
                  `reportVisit?builderId=${selectedBuilder}&startDate=${new Date(
                    startDate,
                  ).toISOString()}&endDate=${new Date(endDate).toISOString()}`,
                );
              }}
            >
              <VisitsIcon className={classes.cardIcon} />
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Visit Report
                </Typography>
                <Typography className={classes.cardDescription} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              elevation={3}
              className={`${classes.card} ${classes.disabled}`}
            >
              <InventoryIcon className={classes.cardIcon} />
              <div className={classes.comingSoon}>
                <span>Coming Soon</span>
                <WatchLater className={classes.comingSoonIcon} />
              </div>
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Inventory Report
                </Typography>
                <Typography className={classes.cardDescription} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              elevation={3}
              className={`${classes.card} ${classes.disabled}`}
            >
              <BatteryFull className={classes.cardIcon} />
              <div className={classes.comingSoon}>
                <span>Coming Soon</span>
                <WatchLater className={classes.comingSoonIcon} />
              </div>
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Battery Report
                </Typography>
                <Typography className={classes.cardDescription} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              elevation={3}
              className={`${classes.card} ${classes.disabled}`}
            >
              <Description className={classes.cardIcon} />
              <div className={classes.comingSoon}>
                <span>Coming Soon</span>
                <WatchLater className={classes.comingSoonIcon} />
              </div>
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Invoice Report
                </Typography>
                <Typography className={classes.cardDescription} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Reports;
