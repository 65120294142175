import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({

  dropZoneContainer: {
    textAlign: 'center',
    padding: '20px 20% 50px 20%',
  },

  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '20px',

  },

  subtitle: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 400,
  },

  dropZone: {
    paddingTop: '30px',
    height: '205px',
    background: '#FFFFFF',
    border: `1px dashed ${theme.appPalette.orange}`,
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginBottom: '14px',
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  supportText: {
    color: '#8E8B9980',
  },

  inputForm: {
    padding: '20px 20% 50px 20%',
  },

  colorContainer: {
    height: '40px',
    width: '40px',
    margin: '20px 10px 0 0',
    borderRadius: '5px',
  },

  radio: {
    '&.Mui-checked': {
      color: `${theme.appPalette.orange}`,
    },
  },

  progressBar: (props: {color: string}) => ({
    marginTop: '20px',
    borderRadius: '10px',
    overflow: 'hidden',
    border: `1px solid ${props.color}`,
  }),

  progressStatus: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '1.28',
  },

  logo: {
    width: '270px',
    height: '70px',
    objectFit: 'contain',
  },

  webflowLink: {
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },

  card: {
    padding: '0 4px',
    borderRadius: 10,
    '& + div': {
      marginTop: 16,
    },
  },

  cardTitle: {
    marginRight: '10px',
    fontSize: 20,
    fontWeight: 600,
  },

  title: {
    display: 'block',
    fontSize: '14px',
    textTransform: 'uppercase',
    color: theme.appPalette.orange,
  },

  textArea: {
    padding: '15px',
    width: '60%',
    resize: 'none',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#e3e3e3',
  },

  tourNowHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    maxWidth: '541px',
    width: '100%',
    marginBottom: '20px',
  },

  previewContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100dvh',
    width: '100%',
    backgroundColor: '#212121a1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },

  previewModal: {
    width: '90%',
    height: '90%',
    backgroundColor: 'white',
    borderRadius: '5px',
    border: '1px solid #F0F0F0',
    padding: '1rem',
    overflowY: 'hidden',
    zIndex: 10000,

    '&::before': {
      content: '',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100dvh',
      backgroundColor: 'red',
    },
  },

  closeBtn: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    display: 'block',
    margin: '0 0 5px auto',
    fontSize: '18px',
    cursor: 'pointer',
  },

  pickerText: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginRight: '20px',
  },

  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
